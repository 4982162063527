import * as React from "react";

import ComplexCodeSampleHtml from './ComplexCodeSampleHtml.js'

export default function ComplexCodeSample() {
    return (
        <div dangerouslySetInnerHTML={{__html: ComplexCodeSampleHtml}}/>
    )
}

