import * as React from "react";

import SignalToNoiseGutHtml from './SignalToNoiseGutHtml.js'

export default function SignalToNoiseGut() {
    return (
        <div dangerouslySetInnerHTML={{__html: SignalToNoiseGutHtml}}/>
    )
}

