import * as React from "react";

import GruseligeExceptionsHtml from './GruseligeExceptionsHtml.js'

export default function GruseligeExceptions() {
    return (
        <div dangerouslySetInnerHTML={{__html: GruseligeExceptionsHtml}}/>
    )
}

