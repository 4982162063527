const GruseligeExceptions = `
<style>.keyword  { color: #518db0  } .type  { color: #45bdb3  } .comment  { color: green    } .quotes  { color: #c49779   } </style>
<pre>
public <span class="keyword">Benutzer</span> SucheBenutzer(<span class="keyword">string</span> suchtext)
{
    <span class="keyword">var</span> benutzer = _controller.SucheBenutzer(suchtext);
    <span class="keyword">if</span>(benutzer == null)
    {
        <span class="keyword">throw new</span> <span class="type">UserNotFoundException</span>(suchtext);
    }
    <span class="keyword">return</span> benutzer;
}
</pre>       


`
export default GruseligeExceptions