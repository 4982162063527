import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BreadCrumbs from "../../components/BreadCrumbs";
import {Link} from 'gatsby'
import {StaticImage} from "gatsby-plugin-image";
import ComplexCodeSample from "../../components/CodeSnippets/ComplexCodeSample";
import SignalToNoiseGut from "../../components/CodeSnippets/SignalToNoiseGut";
import GruseligeExceptions from "../../components/CodeSnippets/GruseligeExceptions";

export default function TestDrivenDevelopment() {
    const breadcrumbs = [
        {name: 'Artikel', href: '/Artikel', current: false},
        {name: 'Test Driven Development (TDD)', href: '/Artikel/TestDrivenDevelopment', current: true},
    ]

    return (
        <>
            <Layout>
                <Seo title="Artikel: Test Driven Development (TDD)"/>
                <BreadCrumbs pages={breadcrumbs}/>
                <div className="relative py-16 bg-white overflow-hidden max-w-3xl mx-auto mt-10 sm:rounded-lg">
                    <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                            <svg
                                className="absolute top-12 left-full transform translate-x-32"
                                width={404}
                                height={384}
                                fill="none"
                                viewBox="0 0 404 384"
                            >
                                <defs>
                                    <pattern
                                        id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect x={0} y={0} width={4} height={4} className="text-gray-200"
                                              fill="currentColor"/>
                                    </pattern>
                                </defs>
                                <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"/>
                            </svg>
                            <svg
                                className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                                width={404}
                                height={384}
                                fill="none"
                                viewBox="0 0 404 384"
                            >
                                <defs>
                                    <pattern
                                        id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect x={0} y={0} width={4} height={4} className="text-gray-200"
                                              fill="currentColor"/>
                                    </pattern>
                                </defs>
                                <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
                            </svg>
                            <svg
                                className="absolute bottom-12 left-full transform translate-x-32"
                                width={404}
                                height={384}
                                fill="none"
                                viewBox="0 0 404 384"
                            >
                                <defs>
                                    <pattern
                                        id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect x={0} y={0} width={4} height={4} className="text-gray-200"
                                              fill="currentColor"/>
                                    </pattern>
                                </defs>
                                <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"/>
                            </svg>
                        </div>
                    </div>
                    <div className="relative px-4 sm:px-6 lg:px-8">
                        <div className="text-lg max-w-prose mx-auto">
                            <h1>
                                <span
                                    className="block text-base text-center text-blue-500 font-semibold tracking-wide uppercase">
                                  Schneller, bessere Software entwickeln
                                </span>
                                <span
                                    className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                  Test Driven Development
                                </span>
                            </h1>
                            <p className="mt-8 text-xl text-gray-500 leading-8">
                                Über Test-Driven-Design (TDD) wurde schon sehr viel geschrieben und dieser Artikel soll
                                und kann keine Einführung in das Thema sein. Er soll vielmehr Entwickler dazu animieren,
                                sich mit dem Thema auseinanderzusetzen. Ich beschreibe die Vorteile von TDD aus
                                meiner persönlichen Entwicklungserfahrung und hoffe, dass es den einen oder anderen den
                                entscheidenden Anstoß gibt, TDD einmal selbst auszuprobieren.
                            </p>
                        </div>
                        <div className="mt-6 prose prose-blue prose-lg prose-quoteless text-gray-500 mx-auto">
                            <h2>Der TDD Grundzyklus</h2>
                            <p>
                                Statt gleich die gewünschten Klassen zu implementieren, fängt man mit einem Test an.
                                Dieser schlägt natürlich fehl, da es ja noch keinen Code gibt, der die eigentlich zu
                                testende Funktion umsetzt. Im nächsten Schritt implementiert man den Code solange, bis
                                der Test grünes Licht gibt.
                            </p>
                            <p>An diesem Punkt hat man nun eine funktionierende Methode. Jetzt geht man hin und überlegt
                                sich, wie man den Code noch ein bisschen schöner / sauberer machen kann. Anschließend
                                prüft man den Test wieder. Diesen Zyklus wiederholt man solange, bis man zufrieden
                                ist.</p>
                            <div className="flex items-center justify-center text-sm">
                                <StaticImage
                                    src="../../images/TDD-Grundzyklus.png"
                                    formats={["auto", "webp", "avif"]}
                                    alt="Der TDD Grundzyklus"
                                />
                            </div>
                            <h2>Arten von Tests</h2>
                            <p>Ich halte die Einteilung von Tests in die folgenden Kategorien für sinnvoll und
                                ausreichend:</p>
                            <ul>
                                <li><span className="font-bold">Unit Tests:</span> Ein Unit Test testet</li>
                            </ul>
                            <p></p>
                            <h2>Frühes Testen vs. Spätes Testen</h2>
                            <p>In den meisten meiner Projekte wurde eher spät mit dem Testen angefangen, also kurz vor
                                dem offiziellen Release. Einfache Unit- und Integration-Tests wurden zwar früher
                                entwickelt, aber die richtigen End-to-End-Tests und Acceptance-Tests kamen erst ganz
                                spät. Und dann passiert praktisch immer das Gleiche: die Tests fördern Probleme zutage,
                                an die bislang keiner gedacht hat. Wenn es ganz schlecht läuft, entdeckt man
                                grundsätzliche Design-Probleme, die nicht mal eben schnell behoben werden können,
                                sondern einen beträchtlichen Teil an Neuentwicklung benötigt!</p>
                            <p>Jetzt können zwei Dinge passieren:</p>
                            <ul>
                                <li>Das Release wird verschoben und das Problem sauber behoben.</li>
                                <li>Es wird ein Work-around gefunden, der das Problem kaschiert, so dass das Release
                                    trotzdem stattfinden kann. Es wird beschlossen, bei nächster Gelegenheit das Problem
                                    sauer zu beheben.
                                </li>
                            </ul>
                            <p>In der Regel wird sich für Letzteres entschieden. Ob das Design-Problem in der Folge
                                behoben wird, ist fraglich. Falls es nicht behoben wird, geht man eine technische Schuld
                                ein. Meistens dauert es nicht lange, bis man genau wegen dieser technischen Schuld eine
                                andere Funktion nicht sauber umsetzen kann und für diese ebenfalls eine weitere
                                technische Schuld eingehen muss. Je mehr dieser Schulden gemacht werden, desto
                                schwieriger wird es, zukünftig Funktionen sauber umzusetzen.</p>
                            <p>Durch TDD ist die Gefahr dieses Teufelskreises deutlich geringer. Im Idealfall baut man
                                ganz früh schon End-to-End-Tests und Acceptance-Tests, damit grundsätzliche Probleme
                                sofort sichtbar werden.</p>
                            <p>Das Problem dabei ist: nach außen sieht es am Anfang so aus, als würde das Projekt keine
                                Fortschritte machen, weil nichts Sichtbares vorgewiesen werden kann. Es muss so viel
                                eingerichtet, konfiguriert, gemockt usw. werden, dass Projektverantwortliche leicht
                                nervös werden. Selten wird erkannt, dass durch das frühe Lösen dieser Probleme hinten
                                raus sehr viel Zeit gewonnen wird. Das wird von Projektverantwortlichen leider nicht
                                immer so gesehen; im Gegenteil: aus Erfahrung wissen sie, dass es kurz vor dem Release
                                immer zu Chaos und Verzögerung kommt und sie denken, wenn sie jetzt schon am Anfang
                                keinen Fortschritt sehen, wie soll es erst kurz vor dem Release werden?</p>
                            <p>Frühes Testen dreht aber diesen Spieß um und reduziert das Chaos am Ende deutlich
                                spürbar. Das folgende Schaubild soll das illustrieren:</p>
                            <StaticImage
                                src="../../images/FruehTesten-SpaetTesten.png"
                                formats={["auto", "webp", "avif"]}
                                alt="Früh testen vs. Spät testen"
                            />
                            <p>Ein weiterer häufig zu beobachtender Negativeffekt von spätem Testen ist: sobald man
                                anfängt, einen Test zu entwickeln, stellt man fest, dass die Komponenten in der aktuell
                                implementierten Form gar nicht testbar sind!</p>
                            <p>Es könnte zum Beispiel sein, dass die zu testenden Daten aufgrund des Zugriffslevel
                                (protected / private) gar nicht zugreifbar sind. Das Design sieht es einfach nicht vor.
                                Man sollte jetzt aber nicht die für den Test notwendigen private Properties einfach auf
                                public umstellen, nur damit man testen kann. Bei einem solchen Fall merkt man, dass
                                nicht von Anfang an ans Testen gedacht wurde. Hätte man TDD gemacht, wäre das sofort
                                aufgefallen und man hätte gleich gemerkt, dass mit dem Design etwas nicht stimmt.
                            </p>
                            <p>Fortsetzung folgt...</p>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

