const ComplexCodeSampleHtml = `
<style>.keyword  { color: #518db0  } .type  { color: #2B91AF  } .comment  { color: green    } .quotes  { color: #c49779   } </style>
<pre>
<span class="keyword">private</span> <span class="keyword">void</span> Confirm(object input, <span class="keyword">string</span> classification)
{
    <span class="keyword">if</span> (input != null)
    {
        <span class="keyword">var</span> inputType = input.GetType();
        <span class="keyword">foreach</span> (<span class="keyword">var</span> pi in inputType.GetProperties())
        {
            <span class="keyword">var</span> attribute = pi.GetCustomAttributes(false)
                .Where(a => a is DatasetValidationAttribute)
                .Cast<DatasetValidationAttribute>()
                .FirstOrDefault();
            <span class="keyword">var</span> classificationName = classification + <span class="quotes">"."</span> + pi.Name;
            <span class="keyword">if</span> (string.IsNullOrEmpty(classification) == true)
            {
                classification = pi.Name;
            }

            <span class="keyword">var</span> propertyValue = pi.GetValue(input);
            <span class="keyword">if</span> (propertyValue != null)
            {
                <span class="keyword">if</span> (pi.PropertyType.IsClass && pi.PropertyType != typeof(String))
                {
                    <span class="keyword">if</span> (typeof(IEnumerable).IsAssignableFrom(pi.PropertyType) == false)
                    {
                        Confirm(propertyValue, classification);
                    }
                    <span class="keyword">else</span>
                    {
                        <span class="keyword">var</span> index = 0;
                        <span class="keyword">var</span> enumerable = propertyValue as IEnumerable;
                        <span class="keyword">if</span> (enumerable != null)
                        {
                            <span class="keyword">foreach</span> (<span class="keyword">var</span> item in enumerable)
                            {
                                <span class="keyword">var</span> itemType = item.GetType();
                                <span class="keyword">if</span> (itemType.IsClass && itemType != typeof(String))
                                {
                                    Confirm(item, classification+$<span class="quotes">"[{index}]"</span>);
                                }
                                <span class="keyword">else</span>
                                {
                                    <span class="keyword">if</span> (attribute != null && itemType == typeof(String))
                                    {
                                        StartConfirmation(attribute, classification + $<span class="quotes">"[{index}]"</span>,
                                            item.ToString());
                                    }
                                }

                                index++;
                            }
                        }
                    }
                }
                <span class="keyword">else</span>
                {
                    <span class="keyword">if</span> (attribute != null && pi.PropertyType == typeof(String))
                    {
                        StartConfirmation(attribute, classification, propertyValue.ToString());
                    }
                }
            }
        }
    }
}
</pre>    
    
    
`

export default ComplexCodeSampleHtml