const SignalToNoiseGut = `
<style>.keyword  { color: #518db0  } .type  { color: #2B91AF  } .comment  { color: green    } .quotes  { color: #c49779   } </style>
<pre>
<span class="keyword">public</span> <span class="keyword">void</span> GeneriereLebenslauf(<span class="keyword">int</span> benutzerId)
{
    FindeBenutzer(benutzerId)
        .OnSuccess(ErzeugeLebenslauf)
        .OnError(LogErrorMessage);
}
</pre>       


`
export default SignalToNoiseGut